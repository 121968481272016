// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b1_kz";
export var caseStudyBackground__lineColor = "b1_kq";
export var caseStudyHead__imageAbsolute = "b1_kJ";
export var caseStudyHead__imageWrapper = "b1_kn";
export var caseStudyProjectsSection = "b1_kB";
export var caseStudyQuote__bgLight = "b1_kx";
export var caseStudyQuote__bgRing = "b1_kw";
export var caseStudySolution__ring = "b1_kt";
export var caseStudyTech = "b1_ky";
export var caseStudy__bgDark = "b1_km";
export var caseStudy__bgLight = "b1_kl";